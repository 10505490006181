import { graphql } from 'gatsby';
import React from 'react';
import { IconContext } from 'react-icons';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import profilePic from '../../content/assets/profile-pic.jpg';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Home"
        keywords={[`breakingjs`, `gatsby`, `javascript`, `react`, `mbeaudru`]}
      />
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <Box>
          <Text
            as="h2"
            mt={[4]}
            fontSize={[5]}
            fontWeight={100}
            css={`
              color: ${({ theme }) => theme.colors.textNeutral};
            `}
          >
            Who am I ?
          </Text>

          <Flex
            mt={[3, 4]}
            flexDirection={['column', 'row']}
            alignItems={['center', 'flex-start']}
          >
            <ProfileImage as="img" src={profilePic} p={1} />

            <Flex
              ml={[0, 3]}
              mt={[3, 2]}
              alignItems={['center', 'flex-start']}
              flexDirection="column"
            >
              <Text as="h3" fontSize={[4]} fontWeight={300} mb={3}>
                Manuel Beaudru{' '}
                <Box
                  as="a"
                  ml={2}
                  href="https://twitter.com/mbeaudru"
                  target="_blank"
                >
                  <FaTwitter />
                </Box>
                <GithubLink link="https://github.com/mbeaudru/" />
                <LinkedInLink />
              </Text>

              <Box as={P} mb={3}>
                I work as a front-end engineer{' '}
                <a href="https://twitter.com/Mirakl" target="_blank">
                  @Mirakl
                </a>{' '}
                and I am very fond of JavaScript and of the ReactJS ecosystem.
              </Box>

              <Box as={P} mb={3}>
                One thing I really enjoy to dig in new technologies and write
                about my discoveries so that everyone can benefit from them.
              </Box>

              <Box as={P}>
                Besides work, I've been sharing my life with{' '}
                <a href="https://twitter.com/Yuki_kko_" target="_blank">
                  @Yuki_kko_
                </a>{' '}
                for more than 6 years now (already ?!), I'm a huge fan of
                Nintendo videogames and drama TV Shows (as you can guess looking
                at the favicon!)
              </Box>
            </Flex>
          </Flex>

          <Flex>
            <Box>
              <Text as="h3" fontSize={[4]} fontWeight={300}>
                Some of my work
              </Text>

              <Box as="ul">
                <li>
                  <a
                    href="https://github.com/mbeaudru/modern-js-cheatsheet"
                    target="_blank"
                  >
                    Modern JS Cheatsheet
                  </a>
                </li>

                <Box as={P} ml="-40px" mt={3}>
                  It's a summary of the features and syntaxes that are used a
                  lot in modern JS codebases (most of them have been brought by
                  the JavaScript ES2015 standard).
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  It's by far my most popular work at the time of writing: >18k
                  stars on Github, more than 200 readers / day and 60
                  contributors !
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  In a past job at{' '}
                  <a href="https://twitter.com/Dassault3DS" target="_blank">
                    Dassault Systemes
                  </a>{' '}
                  I had to help some interns grow fast in JavaScript to maintain
                  a modern codebase. I realized that I couldn't find a resource
                  that matched their needs, so I wrote one ! When done, I
                  decided to share it on reddit just in case, and that's it!
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  I learned many lessons from this experience:{' '}
                  <strong>first</strong>, if you feel like something is missing
                  or if you can't find the right tool on the web, do it ! You
                  probably aren't the only one feeling this way and it might
                  help a lot of people.
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  <strong>Second</strong>, most of the feedbacks you'll get on
                  HackerNews are harsh but it doesn't mean that the majority
                  feels the same. The number of visits and the "stars" speaks
                  for themselves.
                </Box>

                <Box as="li" mt={3}>
                  <a href="https://breakingjs.com" target="_blank">
                    BreakingJS blog
                  </a>
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  I've always wanted to have a space other than medium or github
                  to share my thoughts or work, so I took some time to make
                  breakingjs.com !
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  This blog is powered by GatsbyJS and I grabbed some ideas from
                  the{' '}
                  <a href="https://overreacted.io" target="_blank">
                    overreacted.io
                  </a>{' '}
                  sources.
                </Box>

                <Box as="li" mt={3}>
                  <a
                    href="https://github.com/mbeaudru/ethereum-todolist"
                    target="_blank"
                  >
                    Ethereum (Blockchain) TodoList
                  </a>
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  A todoList ethereum sample dApp made with create-react-app,
                  Truffle and web3. I made this because I needed to learn how to
                  make a{' '}
                  <a
                    href="https://en.wikipedia.org/wiki/Decentralized_application"
                    target="_blank"
                  >
                    dApp
                  </a>{' '}
                  and I thought "if I can make a todolist with this technology I
                  could go a step further next!".
                </Box>

                <Box as={P} ml="-40px" mt={3}>
                  Indeed, for my end of study project I have been working for{' '}
                  <a href="https://twitter.com/Deloitte" target="_blank">
                    @Deloitte
                  </a>{' '}
                  with{' '}
                  <a href="https://twitter.com/JBWatenberg" target="_blank">
                    @JBWatenberg
                  </a>{' '}
                  on a project which goal was to both guarantee the safety of
                  the user keys in storing them in a{' '}
                  <a
                    href="https://en.wikipedia.org/wiki/Hardware_security_module"
                    target="_blank"
                  >
                    HSM
                  </a>{' '}
                  and to lower the barrier entry to use blockchain powered apps.
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </IconContext.Provider>
    </Layout>
  );
};

const P = styled.p`
  text-align: justify;
`;

const ProfileImage = styled(Box)`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #ececec;
`;

const GithubLink = ({ link, ...props }) => (
  <Box
    as="a"
    ml={1}
    css={`
      color: black;
    `}
    href={link}
    target="_blank"
    {...props}
  >
    <FaGithub />
  </Box>
);

const LinkedInLink = ({ ...props }) => (
  <Box
    as="a"
    ml={1}
    css={`
      color: #4875b4;
    `}
    href="https://www.linkedin.com/in/manuel-beaudru-09b6aa98/"
    target="_blank"
    {...props}
  >
    <FaLinkedin />
  </Box>
);

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
